<template>
  <div class="bg">
    <el-carousel
      class="honeBannerBox"
      height="auto"
      :interval="4000"
      :indicator-position="swiperList.length>1?'outside':'none'"
      :arrow="swiperList.length>1?'hover':'never'"
    >
      <!-- 图片用于占位： 实现banner高度自适应 -->
      <img v-if="swiperList.length" class="maskBannerImg" :src="swiperList[0].picUrl" width="100%"  alt="banner">
      <el-carousel-item v-for="(item,index) in swiperList" :key="index">
        <a :href="item.link" target="_blank">
          <img class="swiper-img" :src="item.picUrl"  alt="banner">
        </a>
      </el-carousel-item>
    </el-carousel>
    <div class="newsBox" v-if="newsList.length">
      <div class="newsList content-block">
        <router-link class="dl item" v-for="(item,index) in newsList" :key="index"  :to="{path:'/home/news/info',query:{newsId:item.id}}">
          <dt class="dt ellipsis2">{{item.title}}</dt>
          <dt class="dd">{{item.publishTime | parseTime('{y}-{m}-{d}')}}</dt>
        </router-link>
        <router-link class="more el-icon-arrow-right" to="/home/news/list"></router-link>
      </div>
    </div>
    <div class="content-block">
      <div class="document">
        <section class="titleBox">
          <h3 class="titleContent" aria-atomic="true">标准文档</h3>
          <p class="secondTitle" aria-atomic="true">统一标准，服务开发者，推动行业标准化和一体化</p>
          <div class="titleBottom">
            <svg-icon class="hidden-xs-only titleBottomImg" svg-name="title-white"></svg-icon>
          </div>
        </section>
        <div class="contentBox">
          <div class="swiper-container">
            <div class="swiper-wrapper">
              <div class="swiper-slide"  v-for="(item,index) in homeData" :key="index">
                <div @mouseenter="getInfo(index)" @mouseleave="drawOut(index)">
                  <a class="contentItem" :href="'/documents/'+item.docMenuId">
                    <div class="firstBox">
                      <img
                        v-if="item.hover"
                        :src="item.picActiveUrl" 
                        :class="'documentImg-hover'"/>
                      <img
                        v-else
                        :src="item.picDefaultUrl" 
                        :class="'documentImg' + index"
                      />
                    </div>
                    <div class="contentExtr text-center ellipsis" aria-atomic="true">
                      {{item.title}} 
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div class="swiper-button-prev el-icon-arrow-left"></div>
            <div class="swiper-button-next el-icon-arrow-right"></div>
          </div>
        </div>
        <div class="flex flex-centent">
          <svg-button
            @click.native="openModal()"
            class="footItem"
            svg-name="document-button"
            svg-hover-name="document-button-hover"
            svg-class-name="documentButton"
            text="参与行业标准制定"
            text-color="#202020"
            text-hover-color="#E0D7AF"
          ></svg-button>
          <a @click="lookMore()" target="_blank">
            <svg-button
              class="footItem"
              svg-name="document-button"
              svg-hover-name="document-button-hover"
              svg-class-name="documentButton"
              text="查看更多文档"
              text-color="#202020"
              text-hover-color="#E0D7AF"
            ></svg-button>
          </a>
        </div>
        
      </div>
    </div>
    <div class="service">
      <div class="content-block">
        <section class="titleBox">
          <h3 class="titleContent">联盟服务</h3>
          <p class="secondTitle">高效服务，助力开发者实现快捷、快速的应用生产</p>
          <div class="titleBottom">
            <svg-icon svg-name="title-black" class="hidden-xs-only titleBottomImg"></svg-icon>
          </div>
        </section>
        <div class="contentDec">
          <el-row style="width: 100%">
            <el-col :span="8" class="hidden-xs-only">
              <div class="documentImgBox">
                <svg-icon class="hidden-xs-only serviceImg" svg-name="service-icon"></svg-icon>
                <svg-icon class="hidden-xs-only line" svg-name="line"></svg-icon>
              </div>
            </el-col>
            <el-col :span="15" :xs="{span:18,offset:3}">
              <section class="ServicetitleBox">
                <svg-icon svg-name="start" class="serviceStart hidden-xs-only"></svg-icon>
                <div class="contentTitle">金标认证</div>
              </section>
              <div
                class="contentItem"
              >金标认证服务旨在甄选出在稳定性、兼容性、性能、功耗、安全、隐私等方面均达到行业领先水平的应用，并在小米、OPPO、vivo、联想等品牌的应用市场中向用户推介。提升应用服务品质，提高用户信任感与认同感。</div>
              <svg-button
                @click.native="$router.push({path: '/home/service'});"
                class="serviceDetailBox"
                svg-name="detail-button"
                svg-hover-name="detail-button-hover"
                svg-class-name="detailButton"
                text="查看详情"
                text-color="#F2E9C1"
                text-hover-color="#333333"
              ></svg-button>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <!-- <div class="content-block">
      <div class="members">
        <section class="titleBox">
          <h3 class="titleContent">理事会单位</h3>
          <p class="secondTitle">携手共赢，推动行业全面发展，服务移动智能生态建设</p>
          <div class="titleBottom">
            <svg-icon svg-name="title-white" class="titleBottomImg hidden-xs-only"></svg-icon>
          </div>
        </section>
        <div class="memberListBox">
          <svg-icon svg-name="OPPO" class="oppo"></svg-icon>
          <svg-icon svg-name="vivo" class="vivo"></svg-icon>
          <svg-icon svg-name="小米logo" class="xiaomi"></svg-icon>
        </div>
        <div class="partnersBox">
          <img class="alibaba" src="@/assets/img/home/alibaba.jpg" />
          <img class="baidu" src="@/assets/img/home/baidu.jpg" />
          <img class="tencent" src="@/assets/img/home/tencent.jpg" />
        </div>
      </div>
    </div> -->
    <div class="partners" v-if="partnersList.length>0"> 
      <div class="content-block">
        <section class="titleBox">
          <h3 class="titleContent">联盟成员</h3>
          <div class="titleBottom">
            <svg-icon svg-name="title-white" class="titleBottomImg hidden-xs-only"></svg-icon>
          </div>
        </section>
        <div class="memberBox">
          <h1>理事长单位</h1>
          <div class="imgBox">
            <div v-for="(item,index) in partnersList" :key="index" class="topBox">
              <img v-if="item.category==1" :src="item.img" alt="">
            </div>
          </div>
        </div>
   
        <div class="memberBox">
          <h1>理事单位</h1>
          <div class="imgBox">
            <div v-for="(item,index) in partnersList" :key="index" class="topBox">
              <img v-if="item.category==2" :src="item.img" alt="">
            </div>
          </div>
        </div>
        <div class="memberBox">
          <h1>生态单位</h1>
          <div class="imgBox">
            <div v-for="(item,index) in partnersList" :key="index" class="topBox">
              <img v-if="item.category==3" :src="item.img" alt="">
            </div>
          </div>
        </div>
        <div class="memberBox" v-if="flag">
          <h1>合作伙伴</h1>
          <div class="imgBox">
            <div v-for="(item,index) in partnersList" :key="index" class="topBox">
              <img v-if="item.category==4" :src="item.img" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footContentBox">
      <p class="footcontentTitle">加入我们，共建移动智能终端新生态</p>
      <router-link to="/console/goldIdentification/list">
        <svg-button
          class="footButtonBox"
          svg-name="foot-button"
          svg-hover-name="foot-hover-button"
          svg-class-name="footButton"
          text="立即加入"
          text-color="#202020"
          text-hover-color="#D8CFA9"
        ></svg-button>
      </router-link>
    </div>
  </div>
</template>
<script>
import SvgButton from "@/components/SvgButton";
import { singleHomeSever,singleNewsSever } from "@/common/api-config";
import Swiper from "swiper"
import 'swiper/dist/css/swiper.css'

export default {
  components: { SvgButton },
  created() {
    this.queryBannerList();
    this.queryNewsList();
    this.queryRecommendList();
    this.queryAllianceMemberList();
  },
  mounted () { 
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    // this.initSwiper();//初始化文档滑动
  },
  data() {
    return {
      homeData: [],
      swiperList: [], //banner
      newsList: [], //资讯
      documentBtnHover: false,
      detailBtnHover: false,
      hover:false,
      partnersList:[],
      flag:false,
    };
  },
  methods: {
    //获取banner
    queryBannerList() {
      singleHomeSever.queryBannerList({
        type:1,
      }).then(res => {
        if (res.code == 0) {
          this.swiperList = res.data;
        } else {
          this.$message.error(result.message);
        }
      });
    },
    queryRecommendList() {
      singleHomeSever.queryRecommendList({}).then(res => {
        if (res.code == 0) {
          for(let i = 0;i<res.data.length;i++){
            res.data[i].hover = false;
          }
          this.homeData = res.data;
          // this.homeData=JSON.parse(JSON.stringify(this.homeData))
          this.$nextTick(()=>{
            this.initSwiper();//初始化文档滑动
          })
        } else {
          this.$message.error(result.message);
        }
      });
    },

    queryAllianceMemberList() {
      singleHomeSever.queryAllianceMemberList({}).then(res => {
        if (res.code == 0) {
          this.partnersList = res.data;
          
         this.flag = this.partnersList.some(item => item.category == 4) 
        } else {
          this.$message.error(result.message);
        }
      });
    },
    //初始化滑动
    initSwiper() {
      this.swiper = new Swiper('.swiper-container', {
          loop:true,//循环轮播
          autoplay: 2000,//可选选项，自动滑动
          slidesPerView: 5,
          loopedSlides :15,
          // slidesPerView: 'auto',
          loopAdditionalSlides:2,
          // initialSlide:1,
          // loopedSlides :6,
          pagination: '.swiper-pagination',
          prevButton: '.swiper-button-prev',
          nextButton: '.swiper-button-next',
      });
      // $('.swiper-container').on('mouseover', ()=> {
      //   this.swiper.stopAutoplay();
      // });
      // $('.swiper-container').on('mouseleave', ()=> {
      //   this.swiper.startAutoplay();
      // });
    },
    //获取资讯
    queryNewsList() {
      singleNewsSever.queryNewsList({
        page:1,
        perPage:4,
      }).then(res => {
        if (res.code == 0) {
          this.newsList = res.data.data;
        } else {
          this.$message.error(result.message);
        }
      });
    },
    openModal() {
      this.$msgBox
        .accountWhiteMessageBox({
          title: "",
          content: ""
        })
        .then(async data => {})
        .catch(val => {});  
    },
    lookMore(){
      let routeUrl = this.$router.resolve({
        path:'/documents/'  ,
      })
      
      window.open(routeUrl.href, '_blank')
    },
    getInfo(index){
      this.homeData[index].hover = true;
      // this.homeData=JSON.parse(JSON.stringify(this.homeData))//深拷贝监听数组变化
    },
    drawOut(index){
      this.homeData[index].hover = false;
      // this.homeData=JSON.parse(JSON.stringify(this.homeData))
    },
  }
};
</script>
<style  lang="scss" scoped>
/deep/ .el-carousel__indicator.is-active button {
  background-color: #d3c792;
}

/deep/ .el-carousel__indicator button {
  background-color: #e6e6e6;
}

.bg {
  width: 100%;
  height: 100%;
  background-color: white;
  .honeBannerBox {
    // /deep/ .el-carousel__item, .el-carousel__mask{
    //   position: relative;
    // }
    .maskBannerImg{
      opacity: 0;
    }
    .swiper-img {
      width: 100%;
      min-height: 300px;
      // object-fit: cover;
      height: auto;
    }
    @media screen and (max-width: 767px) {
      /* 移动端时：不需要占位自适应高度，统一高度488px,图片平铺*/ 
      .maskBannerImg{
        height: 488px;
      }
      .swiper-img {
        height: 488px;
        object-fit: cover;
      }
    }
  }
  .newsBox{
    margin-top: -70px;
    padding: 30px ; 
    padding-top: 100px;
    background: #fff;
    box-shadow: 0px 0px 20px 0px #E8E8E8;
    .newsList{
      position: relative;
      display: flex;
      .dl{
        flex: 1;
        margin-right: 30px;
        padding: 30px;
        cursor: pointer;
        .dt{
          margin-bottom: 30px;
          min-height: 60px;
          font-size: 16px;
          color: #282828;
          line-height: 30px;
        }
        .dd{
          font-size: 14px;
          color: #999;
        }
        &:hover{
          border-radius: 5px;
          background-color: #8F8F9A;
          .dt,.dd{
            color: #fff;
          }
        }
      }
      .more{
        position: absolute;
        right: -15px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 30px;
        color: #8F8F9A;
        cursor: pointer;
        opacity: 0.7;
        &:hover{
          opacity: 1;
        }
      }
    }
    @media screen and (max-width: 767px) {
      .newsList {
        display: block;
      }
    }
  }
  .document {
    box-sizing: border-box;
    padding-top: 77px;
    padding-bottom: 65px;
    overflow: hidden;
    .contentBox {
      margin-top: 45px;
      height: 295px;
      user-select: none;
      .swiper-container{
        width: 100%;
        .el-icon-arrow-left{
          left: -8px;
          background-image: none;
          font-size: 35px;
          color: #8F8F9A;
        }
        .el-icon-arrow-right{
          right: -2px;
          background-image: none;
          font-size: 35px;
          color: #8F8F9A;
        }
      }
      .contentItem {
        display: flex;
        box-sizing: border-box;
        margin: 10px auto;
        width: 184px;
        height: 275px;
        border-radius: 5px;
        background-color: rgba(255, 255, 255, 1);
        box-shadow: 0px 0px 20px 0px rgba(197, 203, 225, 0.3);
        transition: all 0.6s ease;
        background: inherit;
        align-items: center;
        flex-direction: column;
        overflow: hidden;
        cursor: pointer;
        outline:none;
        .firstBox {
          width: 184px;
          height: 174px;
          background-color: #202020;
          border-radius: 5px 5px 0px 0px;
          display: flex;
          align-items: center;
          justify-content: center;
         
          .documentImg0 {
            width: 71px;
            height: 51px;
          }
          .documentImg1 {
            width: 63px;
            height: 63px;
          }
          .documentImg2 {
            width: 62px;
            height: 60px;
          }
          .documentImg3 {
            width: 69px;
            height: 61px;
          }
          .documentImg4 {
            width: 58px;
            height: 62px;
          }
          .documentImg5 {
            margin: auto;
            width: 65px;
            height: 65px;
          }
          .documentImg6 {
            margin: auto;
            width: 65px;
            height: 65px;
          }
          .documentImg7 {
            margin: auto;
            width: 65px;
            height: 65px;
          }
          .documentImg8 {
            margin: auto;
            width: 65px;
            height: 65px;
          }
          .documentImg9 {
            margin: auto;
            width: 65px;
            height: 65px;
          }
          .documentImg10 {
            margin: auto;
            width: 65px;
            height: 65px;
          }
          .documentImg11 {
            margin: auto;
            width: 65px;
            height: 65px;
          }
          .documentImg12 {
            margin: auto;
            width: 65px;
            height: 65px;
          }
          .documentImg13 {
            margin: auto;
            width: 65px;
            height: 65px;
          } 
          .documentImg14 {
            margin: auto;
            width: 65px;
            height: 65px;
          }
          .documentImg-hover{
            margin-top: 40px;
            width: 65px;
            height: 65px;
          }
        }
        .contentExtr {
          width: 100%;
          font-size: 16px;
          height: 44px;
          line-height: 44px;
          margin: 32px auto 0px auto;
          padding: 0 10px;
          color: #333333;
          text-align: center;
          overflow: hidden;
        }
        &:hover {
          background: #e0d7af;
          margin-top: 0px;
          transform: scale(1.1,1.1);
          .contentExtr {
            margin-top: 30px;
            font-size: 18px;
          }
          .firstBox {
            padding-top: 55px;
            background: transparent;
          }
        }
      }
      @media screen and (max-width: 767px) {
        .contentItem {
          &:hover {
            margin: 10px auto;
            background: inherit;
            width: 184px;
            height: 275px;
            border-radius: 5px;
            cursor: pointer;
            background-color: rgba(255, 255, 255, 1);
            box-shadow: 0px 0px 20px 0px rgba(197, 203, 225, 0.3);
            box-sizing: border-box;
            .firstBox {
              background-color: #202020;
            }
            .contentExtr {
              background: transparent;
              color: #333333;
            }
          }
        }
      }
    }
    @media screen and (max-width: 767px) {
      .contentBox {
        width: 300%;
      }
    }
    .footItem {
      position: relative;
      display: block;
      width: 203px;
      height: 44px;
      font-size: 16px;
      margin: 55px;
      .documentButton {
        cursor: pointer;
        width: 203px;
        height: 44px;
      }
    }
  }
  .service {
    background-color: #28262c;
    width: 100%;
    padding-top: 50px;
    padding-bottom: 64px;
    .contentDec {
      margin-top: 35px;
      width: 1246px;
      height: 405px;
      background: url("~@/assets/img/home/serviceBg.png") no-repeat;
      background-size: 100% 100%;
      border-radius: 5px;
      display: flex;

      .documentImgBox {
        width: 372px;
        height: 405px;
        display: flex;
        align-items: center;
        .documentImg {
          width: 113px;
          height: 97px;
          margin-left: 88px;
        }
        .serviceImg {
          width: 151px;
          height: 129px;
          margin-left: 88px;
        }
        .line {
          width: 2px;
          margin: auto 0px auto 78px;
          height: 195px;
          display: block;
        }
      }
      .ServicetitleBox {
        margin-top: 90px;
        display: flex;
        align-items: center;
        .contentTitle {
          font-size: 24px;
          margin-left: 2px;
          font-weight: 600;
          color: #c3ba93;
        }
        .serviceStart {
          width: 20px;
          height: 19px;
          margin-right: 12px;
        }
      }

      .contentItem {
        margin-top: 25px;
        line-height: 26px;
        font-size: 16px;
        width: 475px;
        font-weight: 300;
        color: rgba(255, 255, 255, 1);
      }

      .serviceDetailBox {
        position: relative;
        width: 143px;
        height: 44px;
        margin-top: 32px;
        font-size: 16px;
        .detailButton {
          width: 143px;
          height: 44px;
        }
        .detailBtnText {
          position: absolute;
          border: none;
          background-color: transparent;
          outline: none;
          width: 153px;
          font-size: 16px;
          font-weight: normal;
          color: #f2e9c1;
          cursor: pointer;
          left: 45%;
          top: 47%;
          transform: translate(-50%, -50%);
        }
        .detailBtnTextHover {
          position: absolute;
          border: none;
          background-color: transparent;
          outline: none;
          width: 153px;
          font-size: 16px;
          font-weight: normal;
          color: #333333;
          cursor: pointer;
          left: 45%;
          top: 47%;
          transform: translate(-50%, -50%);
        }
      }
    }
    @media screen and (max-width: 767px) {
      .contentDec {
        width: 100%;
        .contentItem {
          width: 100%;
        }
      }
    }
  }
  // 联盟成员
  .members {
    background-color: #ffff;
    padding-top: 68px;
    padding-bottom: 76px;
    .memberFirstTitle {
      color: #c3ba93;
    }
    .memberSecondTitle {
      font-size: 12px;
      margin-top: 17px;
      font-weight: 300;
      color: rgba(153, 153, 153, 1);
      line-height: 60px;
    }
    .memberListBox {
      margin: 59px auto 0px auto;
      padding-left: 160px;
      .oppo {
        height: 67px;
        width: 170px;
      }
      .vivo {
        height: 67px;
        width: 170px;
        margin-left: 202px;
        margin-right:196px
      }
      .xiaomi {
        height: 52px;
        width: 110px;
      }
    }
    .partnersBox {
      padding-left: 160px;
      .alibaba {
        height: 67px;
        width: 170px;
      }
      .baidu {
        height: 67px;
        width: 170px;
        margin-left: 206px;
        margin-right: 168px;
      }
      .tencent {
        height: 67px;
        width: 170px;
      }
    }
    @media screen and (max-width: 767px) {
      .xiaomi {
        margin-right: 34px;
      }
      .memberListBox {
        width: 100%;
        display: flex;
        padding-left: 0px;
        justify-content: space-around;
        img{
          flex: 1;
          width: 33% !important;
        }
        .vivo {
          margin: 0;
        }
      }
      .partnersBox {
        width: 100%;
        display: flex;
        padding-left: 0px;
        justify-content: space-around;
        img{
          flex: 1;  
          width: 33% !important;
        }
        .baidu {
          margin: 0;
        }
      }
    }
  }
  //战略生态合作伙伴
  .partners{
    // background-color: #fff;
    background: url("~@/assets/img/home/logoBg.png") no-repeat;
    min-height: 400px;
    width: 100%;
    padding-top: 66px;
    padding-bottom: 20px;
    overflow: hidden;
    .content-block{
      width: 1300px;
      .titleBox{
        margin-bottom: 60px;
      }
    }

    .memberBox{
      margin-bottom: 30px;
      h1{
        color: #A79347;
        font-weight: bold;
        font-size: 28px;
      }
      .imgBox{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: start;
        margin-bottom: 20px;
        img{
          width: 240px;
          height: 80px;
          margin-right: 20px;
          margin-top: 20px;
        }
      }
    }
   
  }
}
</style>
